import activity from './m-onboard-students.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Onboard Students',
  icon: 'mdi-account-arrow-right',
  description: 'Onboard students to PilotCity',
  status: true,
  styling: {
    borderColor: '#fec34b'
  },
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-onboard-students',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        link: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
